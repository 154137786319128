<template>
    <LiefengContent>
        <template v-slot:title>
            添加群成员
            <span class="eye" @click.stop="changeEye">
                <Icon :type="hasSensitive ? 'md-eye-off' : 'md-eye'" />
            </span>
        </template>
        <!-- <template v-slot:toolsbarRight>
            <Button style="margin-right: 10px" @click="addCustomBtn" type="primary">添加管理员</Button>
        </template> -->
        <template v-slot:contentArea>
            <LiefengModal :title="'添加群成员'" :value="addGroupStatus" @input="addGroupStatusFn" :fullscreen="false" :width="500" height="500px">
                <template v-slot:contentarea>
                    <Form label-position="left" :label-width="70">
                        <FormItem>
                            <span slot="label" class="validate">方式一:</span>
                            <Input placeholder="输入手机号添加" v-model.trim="customerPhone" :maxlength="11"></Input>
                            <Button style="margin-left: 20px" type="primary" @click="addCustom" :loading="btnLoading">添加</Button>
                        </FormItem>
                        <div style="display: flex; margin: 20px 0 20px 70px" class="list-view" v-for="(item, index) in phoneList" :key="index">
                            <span style="width: 200px">{{ item.username }}</span>
                            <Button style="margin-left: 20px" type="error" @click="deteleRow(index, item)">删除</Button>
                        </div>

                        <FormItem v-if="allList && allList.length">
                            <span slot="label" class="validate">方式二:</span>
                            <span style="color: #333">勾选社区下的管理员，选中则成为管理员，管理员可以对内部管理员和户代表进行管理，对聊天内容进行管理</span>
                        </FormItem>
                        <CheckboxGroup v-model="allSelectList" @on-change="allSelect">
                            <Checkbox v-for="(item, index) in allList" :key="index" :label="item.custGlobalId" border>{{ item.username }}</Checkbox>
                        </CheckboxGroup>
                    </Form>
                </template>
                <template v-slot:toolsbar>
                    <Button type="info" style="margin-right: 10px" @click="addGroupStatusFn(false)">取消</Button>
                    <Button type="primary" @click="saveGroup">确定</Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
//@route("/groupindex")
import LiefengContent from "@/components/LiefengContent3"
import LiefengModal from "@/components/LiefengModal"
import LiefengTable from "@/components/LiefengTable"
export default {
    data() {
        return {
            addGroupStatus: false,

            // 手机号搜索
            customerPhone: "",
            phoneList: [],
            allList: [],
            allSelectList: [],
            btnLoading: false,
        }
    },
    methods: {
        addCustomBtn() {
            this.customerPhone = ""
            this.phoneList = []
            this.allList = []
            this.allSelectList = []
            this.btnLoading = false
            this.selectStaffList()
            this.addGroupStatusFn(true)
        },
        // 删除手机号添加的管理员
        deteleRow(index, row) {
            this.phoneList.splice(index, 1)
            this.allSelectList = this.allSelectList.filter(item => item !== row.custGlobalId)
        },
        // 手机号添加按钮
        addCustom() {
            if (!this.customerPhone || this.customerPhone == "") {
                this.$Message.warning({
                    content: "手机号不能为空",
                    background: true,
                })
                return
            }
            this.phoneGetPeo()
        },
        saveGroup() {
            var selectList = []
            if (!this.phoneList || !this.phoneList.length) {
                if (!this.allSelectList || !this.allSelectList.length) {
                    this.$Message.warning({
                        content: "请添加或者选择管理员",
                        background: true,
                    })
                    return
                }
            }
            if (!this.allSelectList || !this.allSelectList.length) {
                if (!this.phoneList || !this.phoneList.length) {
                    this.$Message.warning({
                        content: "请添加或者选择管理员",
                        background: true,
                    })
                    return
                }
            } else {
                this.allList.map(item => {
                    if (this.allSelectList.join(",").indexOf(item.custGlobalId) > -1) {
                        selectList.push(item)
                    }
                })
            }
            let list = Array.from(new Set([...this.phoneList, ...selectList].map(item => item.custGlobalId)))
                .map(id => {
                    return this.phoneList.find(user => user.custGlobalId === id) || selectList.find(user => user.custGlobalId === id)
                })
                .map(item => {
                    return {
                        mobile: item.mobile,
                        custGlobalId: item.custGlobalId,
                        username: item.username,
                    }
                })
            this.openManagerList(list)
        },
        addGroupStatusFn(status) {
            this.addGroupStatus = status
            if (!status && this.$route.query.type) {
                var index = parent.layer.getFrameIndex(window.name)
                if (index) {
                    //先得到当前iframe层的索引
                    parent.layer.close(index) //再执行关闭
                } else {
                    this.$router.push({
                        path: `/groupcontacter?groupId=${this.$route.query.groupId}`,
                    })
                }
            }
        },
        // 根据手机号获取人
        phoneGetPeo() {
            this.btnLoading = true
            this.$get("/sendmsg/pc/chat/openUser/openUserBefore", {
                mobile: this.customerPhone,
                groupId: this.$route.query.groupId,
            }).then(res => {
                this.btnLoading = false
                if (res && res.data && res.code == 200) {
                    var list = []
                    if (!res.data.mobile || !res.data.username || !res.data.custGlobalId) {
                        this.$Message.warning({
                            content: "该手机号未登录过随约，需激活后才可直接添加",
                            background: true,
                        })
                        return
                    }
                    if (res.data.manager) {
                        this.$Message.warning({
                            content: "当前用户已经是管理员，无需再次添加",
                            background: true,
                        })
                        return
                    }

                    // list.push(res.data.custGlobalId)
                    if (this.phoneList && this.phoneList.length) {
                        var isTure = false
                        this.phoneList.map(item => {
                            if (item.custGlobalId == res.data.custGlobalId) isTure = true
                        })
                        if (isTure) {
                            this.$Message.warning({
                                content: "当前用户已经添加，请勿重复添加",
                                background: true,
                            })
                            return
                        }
                        // this.phoneList.map(item => list.push(item.custGlobalId))
                        // console.log('搜索到的数组',list);
                        // if (list.join(",").indexOf(res.data.custGlobalId) > -1) {
                        //     this.$Message.warning({
                        //         content: "当前用户已经添加，请勿重复添加",
                        //         background: true,
                        //     })
                        //     return
                        // }
                    }

                    if (this.allList && this.allList.length) {
                        this.allList.map(item => {
                            if (item.custGlobalId == res.data.custGlobalId) {
                                this.allSelectList = this.allSelectList.concat([res.data.custGlobalId])
                            }
                        })
                    }
                    this.customerPhone = ""
                    this.phoneList.push(res.data)
                }
            })
        },

        selectStaffList() {
            this.$Message.loading({
                content: "正在加载中",
            })
            this.$get("/sendmsg/pc/chat/openUser/selectUserStaffList", {
                groupId: this.$route.query.groupId,
            }).then(res => {
                this.$Message.destroy()
                this.allList = res.dataList.filter(item => !item.user)
            })
        },
        openManagerList(managers) {
            this.$Message.loading({
                content: "正在添加",
            })
            this.$post(
                "/sendmsg/pc/chat/openUser/openUserList",
                {
                    groupId: this.$route.query.groupId,
                    managers,
                    orgCode: window.sessionStorage.getItem("orgCode"),
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                this.$Message.destroy()
                if (res && res.code == 200) {
                    this.$Message.success({
                        content: "操作成功",
                        background: true,
                    })
                    this.addGroupStatusFn(false)
                    return
                } else {
                    this.$Message.error({
                        content: "操作失败",
                        background: true,
                    })
                }
            })
        },
    },
    async created() {
        if (this.$route.query.type) {
            this.addCustomBtn()
            return
        }
        this.getList()
    },
    components: {
        LiefengContent,
        LiefengModal,
        LiefengTable,
    },
}
</script>

<style scoped lang="less">
/deep/.ivu-input-wrapper {
    width: 200px;
}
/deep/.ivu-checkbox-group-item {
    margin: 5px;
}
</style>
